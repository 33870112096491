// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from '@modules/react';
import { Col } from '@modules/antd/lib/grid';

const AboutLinkHOC = (text: string, link: string) => (): JSX.Element | null => (
    <Col>
        <a href={link} target='_blank' rel='noopener noreferrer'>
            {text}
        </a>
    </Col>
);

export default AboutLinkHOC;

// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

const consts = {
    TERMS_OF_USE: 'https://www.cvat.ai/terms-of-use',
    PRIVACY_POLICY: 'https://www.cvat.ai/privacy',
};

export default consts;

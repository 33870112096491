// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from '@modules/react';
import { PluginEntryPoint, ComponentBuilder } from '@root/components/plugins-entrypoint';
import AboutLinkHOC from './about-link/about-link';
import consts from './consts';

const PLUGIN_NAME = 'About';

const AboutPlugin: ComponentBuilder = ({
    dispatch, REGISTER_ACTION, REMOVE_ACTION,
}) => {
    const TermsOfUse = React.memo(AboutLinkHOC('Terms of use', consts.TERMS_OF_USE));
    const PrivacyPolicy = React.memo(AboutLinkHOC('Privacy policy', consts.PRIVACY_POLICY));

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'about.links.items',
            component: TermsOfUse,
            data: {
                weight: 40,
            },
        },
    });

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'about.links.items',
            component: PrivacyPolicy,
            data: {
                weight: 50,
            },
        },
    });

    return {
        name: PLUGIN_NAME,
        destructor: () => {
            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'about.links.items',
                    component: TermsOfUse,
                },
            });

            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'about.links.items',
                    component: PrivacyPolicy,
                },
            });
        },
    };
};

function register() {
    if (Object.prototype.hasOwnProperty.call(window, 'cvatUI')) {
        (window as any as { cvatUI: { registerComponent: PluginEntryPoint } })
            .cvatUI.registerComponent(AboutPlugin);
    }
}

window.addEventListener('plugins.ready', register, { once: true });
